define(['app'], (app) => {
  const ProductPersonalisation = () => {

    const component = {};

    const _selectors = {
      openModalBtn:'[data-personalisation-modal-btn]',
      startOverBtn:'[data-personalisation-start-over-btn]',
      startOverLink: 'data-personalisation-start-over-url'
    };

    const _publishChannels = {
      showModal: 'productPersonalisation/openModal'
    }


    const _init = (element) => {
      component.element = element;
      component.elements = {
        openModalBtn: component.element.querySelector(component.selectors.openModalBtn),
        startOverBtn: component.element.querySelector(component.selectors.startOverBtn)
      };

      component.bind();

    };

    const _bind = () => {
      component.elements.openModalBtn.addEventListener('click', () => {
        app.publish(component.publishChannels.showModal);
      });
      component.elements.startOverBtn.addEventListener('click', () => {
        const rootUrl = app.utils.getProperty('personalisationRootUrl', 'productPersonalisation');
        const personalisedProductCategory = siteObj.personalisedProductCategory;
        if (personalisedProductCategory) window.location = window.location.origin + rootUrl + personalisedProductCategory + ".list";
        else {
          window.location = component.elements.startOverBtn.getAttribute(component.selectors.startOverLink);
        }
      })
    }

    component.init = _init;
    component.bind = _bind;
    component.selectors = _selectors;
    component.publishChannels = _publishChannels;


    return component;
  };
  return ProductPersonalisation;
});
